<template>
<iframe nonce="" :allow="allowList" sandbox="allow-scripts allow-modals allow-same-origin" :style="style" ref="jsview" src="">
</iframe>


</template>

<script>
import moment from "moment";

export default {
  name: 'JSField',
    props:[ "path", "Sql","allowList"]  ,
    data (){
      return {
          style: {},
          html : ""
      };
  },
  async created (){
     
   
  },
  async mounted(  ) {
     
     if (! window.isCrossOrigin ){
      
          await this.showData(await window.parent.getSql() ,this.path); 

      } else {
        
         await this.showData(await window.getSql() ,this.path);   
      }


      
    },
    methods:{
       async showData ( Sql,path){

       try{

          if(!path){
              path =this.path;
          }

          path = encodeURI(path);
          path = decodeURI(path);
          path = path.replaceAll (  '+', '%2B' )
          const p=path.split('?');
          
          p.shift();
          
          var tmp ="?"+p.shift();
          p.map((d)=>{ tmp+="%3F"+d;
          // (d.replace(/&/g, '%26')); 
          
          });

          const param = new URLSearchParams(   tmp );

          var  q = param.get('q') ;
          var  s = param.get('s') ;
          var  w = param.get('w') ;
          var  h = param.get('h') ;

          if(w){
          this.style.width = w;
          }
          if(h){
            
            this.style.height = h ;
          }
          const particleSize  = param.get('particle_size')  ;
          const  aggregate  = param.get('agg');
          const startDate   = param.get('start')  ;
          const  endDate  = param.get('end');
          
          q = q.replaceAll("{START_DATE}", startDate );
          q = q.replaceAll("{END_DATE}",endDate);   
          q = q.replaceAll("{PARTICLE_SIZE}", particleSize);
          q = q.replaceAll("{AGGREGATE}",  aggregate );   
          q = q.replaceAll("{PARTICLE_SIZE}", particleSize);
          
          q = q.replace("{TODAY}",  moment().format('YYYY-MM-DD') );   
          q = q.replace("{TODAY+1D}", moment().add('d',1).format('YYYY-MM-DD') );   
  
          s = s.replaceAll("%26", "&" );
          
          s = s.replaceAll("{START_DATE}", startDate );
          s = s.replaceAll("{END_DATE}",endDate);   
          s = s.replaceAll("{PARTICLE_SIZE}", particleSize);
          s = s.replaceAll("{TEMPORAL_GRANULARITY}", particleSize );
          s = s.replaceAll("{AGGREGATE}",  aggregate );   
        
   
           Sql.executeAsyncQueue( q , (resu)=>{ 
            
            const script =" try { "
            + "const data="
            + (JSON.stringify(resu)) 
            + "; " 
            + s 
            + " }catch(e){ console.warn('[jsfield warn]:', e)  }";

          //  const nonce =   Math.random().toString(32).substring(2);                
          const randomBytesSize = 16;
          const randomBytes = window.crypto.getRandomValues(new Uint8Array(randomBytesSize));
          const nonce = window.btoa(String.fromCharCode(...randomBytes));

          const jsblob = new Blob([script], { type: 'application/javascript' });
          const dynamicHtml = "<!DOCTYPE html><html><head><meta http-equiv=\"Content-Security-Policy\" content= \"script-src 'nonce-"+nonce+"'\"><script nonce='"+nonce+"' src='"+ URL.createObjectURL(jsblob) +"'><"+"/script><head><body><div id='htmlField'></div><"+"/body><"+"/html>";

           // const htmlblob = new Blob([dynamicHtml], { type: 'text/html' });
            // this.$refs.jsview.src = URL.createObjectURL(htmlblob) ;
             this.$refs.jsview.srcdoc = dynamicHtml;
            // <script src='"+  URL.createObjectURL(jsblob) +"'><"+"/script> 
           //   var st = document.createElement("script");
           //   var ele = document.getElementById(id);
           //   ele.appendChild(s);

           // this.$refs.jsview.contentWindow.postMessage( { s:s ,data:resu},'*');
          });
          await Sql.executeAsync();
          
        
	      
        
       
         }catch (e){
           console.log(e);  
         }
      }
    }
};

</script>
